import './App.css';
import {Hero} from "./Components/Hero/Hero";
import {Programs} from "./Components/Programs/Programs";
import {Reasons} from "./Components/Reasons/Reasons";
import {Plans} from "./Components/Plans/Plans";
import Testimonials from "./Components/Testimonials/Testimonial";
import Join from "./Components/Join/Join";
import Footer from "./Components/Footer/Footer";
function App() {
  return (
    <div className="App">
          {/*<span style={{color: 'white'}}>Subscribe Zainkeepscode</span>*/}
          <Hero/>
          <Programs/>
          <Reasons/>
          <Plans/>
          <Testimonials/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
