import React from "react";
import './Hero.css'
import {Header} from "../Header/Header";
import hero_image from "../../assets/hero_image.png" ;
import hero_image_back from "../../assets/hero_image_back.png" ;
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import {motion} from 'framer-motion'

export const Hero = () => {
    const transition = {type: 'spring',duration : 3}
    const mobile = window.innerWidth<=768  ? true : false ;
    return (
        <>
            <div className="hero">
                <div className="blur hero-blur"></div>
                <div className="left-h">
                    <Header/>
                    {/*    The best ad*/}
                    <div className="the-best-ad">

                        <motion.div initial={{left :  mobile? "178px" :  "238px" }}
                        whileInView={{left : '8px'}} transition={{...transition, type : 'tween'}}
                        >

                        </motion.div>
                        <span>The best fitness clud in the town</span>
                    </div>
                    <div className="hero-text">
                        <div>
                            <span className="stroke-text">Shape </span>
                            <span>Your</span>
                        </div>
                        <div><span>Ideal Body</span></div>
                        <div><span>In here we will help you to shape and build your ideal body and live up your life to fullest </span>
                        </div>
                    </div>
                    {/*    figures*/}
                    <div className="figures">
                        <div>
                            <span>+140</span>
                            <span>expert coaches</span>
                        </div>
                        <div>
                            <span>+978</span>
                            <span>members joined</span>
                        </div>
                        <div>
                            <span>+50</span>
                            <span>fitness programs</span>
                        </div>
                    </div>
                    {/*    Hero button*/}
                    <div className="hero-button">
                        <button className="btn">get started</button>
                        <button className="btn">learn more</button>
                    </div>
                </div>

                <div className="right-h">
                    <button className="btn">Join Now</button>
                    <motion.div className="heart-rate"
                    transition={transition}
                                whileInView={{right : "11rem"}}
                                initial={{right : '-1rem'}}
                    >
                        <img src={Heart} alt="Heart rate"></img>
                        <span>Heart Rate</span><span>116 BPM</span></motion.div>
                    <img src={hero_image} alt="" className="hero-image"/>
                    <motion.img
                        transition={transition}
                        whileInView={{right : "35rem"}}
                        initial={{right : '11rem'}}
                        src={hero_image_back} alt="" className="hero-image-back"/>
                {/*    calories*/}
                <motion.div className="clories"
                            transition={transition}
                            whileInView={{right : "28rem"}}
                            initial={{right : '37rem'}}
                ><img src={Calories} alt=""/><div><span>Calories Burned</span><span>220 kcal</span></div></motion.div>

                </div>
            </div>
        </>
    )
}